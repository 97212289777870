<!-- @format -->

<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="endForm.avatar"
          :text="avatarText(endForm.real_name)"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ endForm.real_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Username" label-for="real_name">
            <b-form-input id="real_name" v-model="endForm.real_name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Surname" label-for="real_name">
            <b-form-input id="real_name" v-model="endForm.lastname" />
          </b-form-group>
        </b-col> <b-col cols="12" md="4">
          <b-form-group label="First Name" label-for="real_name">
            <b-form-input id="real_name" v-model="endForm.firstname" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Country" label-for="country">
            <b-form-input id="country" v-model="endForm.country" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Address 1" label-for="address">
            <b-form-input id="address" v-model="endForm.address" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Address 2" label-for="address">
            <b-form-input id="address2" v-model="endForm.address2" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="State" label-for="country">
            <b-form-input id="state" v-model="endForm.state" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="City" label-for="country">
            <b-form-input id="city" v-model="endForm.city" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="E-mail" label-for="email">
            <b-form-input id="email" v-model="endForm.email" type="email" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Birth" label-for="birth">

            <flat-pickr v-model="endForm.birth" class="form-control" placeholder="Select Date" format="yyyy-MM-dd" />

          </b-form-group>
        </b-col>

        <!--       Gender, multiple choice button group-->
        <b-col cols="12" md="4">
          <b-form-group label="Gender" label-for="gender">
            <BFormRadioGroup v-model="endForm.gender">
              <b-form-radio v-model="endForm.gender" value="1">男</b-form-radio>
              <b-form-radio v-model="endForm.gender" value="2">女</b-form-radio>
            </BFormRadioGroup>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="submitChange"
    >
      Confirm the changes</b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import flatPickr from 'vue-flatpickr-component'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    flatPickr
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance()

    const { resolveUserRoleVariant } = useUsersList()
    const { toast } = useToast()
    const endForm = ref(null)
    onMounted(() => {
      // eslint-disable-next-line no-param-reassign
      endForm.value = props.userData
      endForm.value.gender = endForm.value.gender == '男' ? '1' : '2'
    })
    const refInputEl = ref(null)
    const previewEl = ref(null)
    console.log(props)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        const files = refInputEl.value.files[0]
        const formData = new FormData()
        formData.append('image', files)
        axios.post('/upload/uploadAvatar', formData).then(res => {
          console.log(res)
          endForm.value.avatar = process.env.VUE_APP_BASE_URL.replace('/backend', '/')
            + res.data.url
        })
      }
    )

    const submitChange = () => {
      console.log(endForm)
      axios.post('user/editUserInfo', endForm.value).then(res => {
        console.log(res)
        if (res.code == 0) {
          proxy.$bvModal.msgBoxOk(res.msg)
            .then(value => {
              proxy.$router.push({
                name: 'vip_info',
              })
            })
            .catch(err => {
            // An error occurred
            })
        } else {
          proxy.$bvToast.toast(res.msg)
        }
      })
    }
    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      endForm,
      submitChange,

      domain: process.env.VUE_APP_COM_URL
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

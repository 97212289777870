<!-- @format -->

<template>
  <component is="b-card">
    <b-tabs>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Account Information</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <!--      <b-tab>-->
      <!--        <template #title>-->
      <!--          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />-->
      <!--          <span class="d-none d-sm-inline">Information</span>-->
      <!--        </template>-->
      <!--        <user-edit-tab-information class="mt-2 pt-75" />-->
      <!--      </b-tab>-->

      <!-- Tab: Social -->
      <!--      <b-tab>-->
      <!--        <template #title>-->
      <!--          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />-->
      <!--          <span class="d-none d-sm-inline">Social</span>-->
      <!--        </template>-->
      <!--        <user-edit-tab-social class="mt-2 pt-75" />-->
      <!--      </b-tab>-->
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    // UserEditTabInformation,
    // UserEditTabSocial,
  },

  setup() {
    const { route } = useRouter()
    console.log(route)
    const userData = route.value.params

    return {
      userData,
    }
  },
}
</script>

<style></style>
